.App {
  /* text-align: center; */
  background-color: white;
}

.sidenav {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.01s;
  height: 100vh;  
}

#main {
  transition: margin-left .01s;
  padding: 16px;
}

/* @media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
} */

.item-sidebar_close {
  margin-top: 5px;
  grid-area: sidebar_close;
}
.item-sidebar_header {
  margin-top: 5px;
  margin-bottom: 5px;
  grid-area: sidebar_header;
}
.item-sidebar_refresh {
  margin-top: 5px;
  grid-area: sidebar_refresh;
}
.item-sidebar_sign_out {
  margin-top: 5px;
  grid-area: sidebar_sign_out;
}
.item-sidebar_table {
  grid-area: sidebar_table;
}
.item-sidebar_load_more {
  grid-area: sidebar_load_more;
} 
.item-sidebar_user {
  margin-top: 5px;
  grid-area: sidebar_user;
}

.item-sidebar {
  grid-area: sidebar;
  background: rgb(250, 250, 250) !important;
  z-index: 3;
  text-align: center;
  align-items: top;
  display: grid;
  height: 100vh;
  grid-template-columns: 5rem 15rem 5rem;
  grid-template-rows: 10vh 75vh 15vh;
  grid-template-areas: 
    "sidebar_close sidebar_header sidebar_user"
    "sidebar_table sidebar_table sidebar_table"
    "sidebar_sign_out sidebar_load_more sidebar_refresh";
}

.item-header {
  grid-area: header;
  display: grid;
  z-index: 2;
  position: fixed;
  width: 100%;
  height: 5rem;
  grid-template-columns: auto 5rem;
  grid-template-rows: 100%;
  background-color: black;
  color: white;
  grid-template-areas: 
    "logo expand"
}
.item-header .logo {
  top: 0;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  grid-area: logo;
}

.item-header .expand {
  grid-area: expand;
  text-align: right;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.container {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: auto 25rem;
  grid-template-rows: 5rem auto;
  grid-template-areas: 
    "header sidebar"
    "main sidebar"
    "main sidebar";
}

.item-main {
  grid-area: main;
  z-index: 1;
  display: grid;
  position: relative;
  background-color: grey;
  grid-template-columns: calc(100vw - 25rem);
  grid-template-rows: auto 8rem;
  grid-template-areas: 
    "messages"
    "send";
}

/* MESSAGE SEND */
/* Styling the msg-bottom container */
.item-msg-bottom {
  grid-area: send;
  border-top: 1px solid #ccc;
  bottom: 0;
  height: 8rem;
  width: calc(100vw - 25rem);
  position: fixed;
  background-color: rgb(239 239 239);
}

/* Styling the input field */
.input-group {
  float: right;
  margin-top: 13px;
  margin-right: 20px;
  outline: none !important;
  border-radius: 20px;
  width: 80%;
  height: 6rem;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.input-group>.form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.form-control {
  border: none !important;
  border-radius: 20px !important;
  display: block;
  height: calc(6rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  resize: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-group-text {
  background: transparent !important;
  border: none !important;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: b;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-weight: bold !important;
  cursor: pointer;
}

input:focus {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

.send-icon {
  font-weight: bold !important;
}

/* RECEIVED */
/* MESSAGES LAYOUT OF MAIN  */
.item-message-area {
  grid-area: messages;
}

.area-load-more{
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.received-msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received-msg-inbox {
  width: 60%;
}

.received-msg-inbox p {
  background: #efefef none repeat scroll 0 0;
  border-radius: 10px;
  color: #646464;
  font-size: 14px;
  margin-left: 1rem;
  padding: 1rem;
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 5px 2px;
}
  p {
  overflow-wrap: break-word;
}
/* SENT */

.outgoing-chats {
  overflow: hidden;
  margin: 26px 20px;
}

.outgoing-chats-msg p {
  background-color: rgb(49, 49, 49);
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px 2px;
}
.outgoing-chats-msg {
      float: right;
      width: 60%;
  }

.send-button {
  display:block;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 1px solid black;
  text-align: center;
}
.send-button:hover:enabled {
  background: gray;
  cursor: pointer;
}